import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import {
  AddManually,
  IManualRespondentInfo as IManualRespondent,
  ManualRespondentInputs,
} from "./addManually";
import { IListRespondent, SelectFromList } from "./selectFromList";
import { PreviewPopup } from "../../../profileList/addProfile/emailSMSInvitation/previewPopup";
import { RespondentStatus } from "./respondentStatus";
import {
  GetTypeOfRoleName,
  ProfileStatus,
} from "../../../profileList/profileEnums";
import { CustomModal as RespondentsLimitReachedModal } from "../../../../components/modals/customModal";
import { Instruction } from "@app/components/instruction/instruction";
import { GuidePages } from "@app/containers/commonEnums";
import {
  Step,
  StepComponent,
  StepComponentProps,
} from "../../createProfilePage";
import {
  CompletedStepPanel,
  CurrentStepPanel,
  UpcomingStepPanel,
} from "../../panels";
import { RoleSettingsWithEditOption } from "@app/containers/profileList/addProfile/roleSettings/roleSettingsWithEditOption";
import { useRolePage } from "../rolePage/hooks";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParticipantProfileAuth } from "../../useParticipantProfileAuth";
import { RootState } from "@app/store/configureStore";
import { inviteRespondentsInstructionSteps } from "@app/components/instruction/instructionSteps";
import { IFocusError } from "@app/components/formComponents/input";
import { ProfileId, UserId } from "@app/containers/reducer";
import { getParticipantRespondentsByParticipantId } from "@app/containers/profileList/editProfile/actions";
import {
  SendRespondentsInvitation,
  ICreateManualRespondentsBody,
  ICreateRespondentsParams,
  IRespondentEmailPreviewBody,
} from "./sendRespondentsInvitation";
import {
  createManualProfileParticipantRespondents,
  getRespondentEmailPreview,
  getUserForRespondents,
} from "../../actions";
import { setSpinner } from "@app/containers/actions";
import { IProfileRespondentsList } from "@app/containers/profileList/editProfile/interface";
import { ValidationHelper } from "@app/containers/validationHelper";
import { useApiEndpoints } from "@app/api/end-points";
import { range } from "@app/containers/utils";
import { IProfileDetails } from "../../reducer";

/**
 * TODO: we probably have this kind of logic duplicated elsewhere - i just don't know where.
 */
function getShortName(name: string): string {
  const parts = name.trim().split(/\s+/);
  if (parts.length === 1) {
    return name;
  }

  // first name and the initial of the last name. should work
  // for people with more than two names.
  return [parts[0], parts[parts.length - 1].charAt(0)]
    .filter((x) => !!x)
    .join(" ");
}

type InviteRespondentsStepProps = StepComponentProps & {
  /**
   * This is a trashy workaround because this component is _extremely_ tightly
   * coupled to the hook 'useInviteRespondent'. It's so coupled that the hook
   * itself is useless - it could just be inline code. To pull the state
   * upwards would require massive changes, and I'm not in a mood to do that.
   *   -johan, 2024-10-17
   */
  variant:
    | { kind: "step" }
    | { kind: "modal"; onSubmit: () => unknown; onClose: () => unknown };
};

enum NavTabEnums {
  None = 0,
  Select_From_List = 1,
  Add_Manually = 2,
}

export interface IUserRespondentsBody {
  profileId: ProfileId;
  searchText: string;
}

const initialErrorState: IFocusError = { errorMessage: "", touched: false };

const EMPTY_MANUAL_RESPONDENT: IManualRespondent = {
  id: 1,
  name: "",
  emailAddress: "",
  phoneNumber: "",
  nameError: initialErrorState,
  emailError: initialErrorState,
  phoneNumberError: initialErrorState,
  instructionLanguageId: null,
};

/**
 * Balances the respondents according to 'roleCanBeChangedByParticipant' and 'noOfRespondents'.
 * When the former is falsy the number of respondents is static which means that adding a list
 * respondent must necessarily shrink the number of manual respondents.
 *
 * The 'right' array argument is mutated in-place.
 */
function maybeBalanceRespondents(
  profile: IProfileDetails,
  left: ReadonlyArray<IListRespondent>,
  right: Array<IManualRespondent>,
): void {
  if (profile.roleCanBeChangedByParticipant) {
    return;
  }

  const target = Math.max(profile.noOfRespondents - left.length, 0);

  // remove respondents if there are too many, add blank respondents if there are too few.
  while (right.length > target) {
    right.pop();
  }
  while (right.length < target) {
    right.push({
      ...EMPTY_MANUAL_RESPONDENT,

      // FIXME: this is not an actual ID, it's used as some kind of row identifier in the view.
      id: right.length + 1,
    });
  }
}

export const InviteRespondents: React.FC<InviteRespondentsStepProps> = (
  props,
) => {
  function onInvited() {
    if (props.variant.kind === "step") {
      props.setStep(Step.SelfAssessment);
    }
  }

  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  // Required to get data from the URL unique code
  // Also has a nasty side effect that sets 'participantProfileDetails' in Redux state.
  useParticipantProfileAuth();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );
  const currentUserLanguages = useSelector(
    (state: RootState) => state.mainReducer.currentUserLanguages,
  );
  const instructionSteps = useMemo(
    () => inviteRespondentsInstructionSteps(languageText),
    [languageText],
  );
  const [originalRespondentsList, setOriginalRespondentsList] = useState<
    IListRespondent[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<NavTabEnums>(
    NavTabEnums.Select_From_List,
  );
  const [respondentsLimitReachedModal, setRespondentsLimitReachedModal] =
    useState<boolean>(false);

  // Select from list states
  const [searchText, setSearchText] = useState<string>("");
  const [listRespondents, setListRespondents] = useState<IListRespondent[]>([]);
  const [refetchList, setRefetchList] = useState<boolean>(false);
  const [instructionMessage, setInstructionMessage] = useState<string>("");

  // there are basically two scenarios here, related to 'roleCanBeChangedByParticipant':
  //
  //   - when the value is true the participant is allowed to modify the number of respondents,
  //     which means that we can safely ignore 'noOfRespondents' and just output a single
  //     row (so they see something).
  //
  //   - when the value is false the sum of 'list respondents' and 'manual respondents'
  //     must necessarily be equal to 'noOfRespondents'. when the component loads there are
  //     never any list respondents.
  const needsManualRespondentsCount = props.profile
    .roleCanBeChangedByParticipant
    ? 1
    : props.profile.noOfRespondents;
  const [manualRespondents, setManualRespondents] = useState<
    IManualRespondent[]
  >(
    range(0, needsManualRespondentsCount).map((n) => {
      return {
        ...EMPTY_MANUAL_RESPONDENT,
        id: n + 1,
      };
    }),
  );
  const [previewHTML, setPreviewHTML] = useState<string>("");

  // FIXME: this state is super confusing. when we're at the 'invite respondents' step
  //   this will be empty, because we haven't invited anyone yet (of course). when this
  //   component is reused in the 'waiting for respondents' step this thing will have data.
  //   -johan, 2024-11-27
  // Respondents status states
  const [invitedRespondentsList, setInvitedRespondentsList] =
    useState<IProfileRespondentsList>({
      participantLink: "",
      respondents: [],
      noOfRespondents: 0,
      respondentsAnswered: 0,
      respondentsInvited: 0,
    });
  const [refetchInvitedRespondentsList, setRefetchInvitedRespondentsList] =
    useState<boolean>(false);

  function setInstructionLanguageForAllRespondents(languageCode: string): void {
    // FIXME: this method curiously only operates on manual respondents. is that correct?
    //   maybe we're assuming that the 'list respondents' already have a preferred language
    //   on the user level?

    const lang = currentUserLanguages.find((it) => it.name === languageCode);
    const next = manualRespondents.map((r) => {
      return {
        ...r,
        instructionLanguageId: lang?.id || null,
      };
    });
    setManualRespondents(next);
  }

  // Select from list , Add manually useEffect
  useEffect(() => {
    if (userLanguage.userLanguageCode) {
      setInstructionLanguageForAllRespondents(userLanguage.userLanguageCode);
    }
  }, [props.profile, userLanguage.userLanguageCode]);

  // Select from list useEffect
  useEffect(() => {
    if (props.status !== "current") {
      return;
    }

    if (props.profile.profileId && !props.profile.isLocked) {
      const body: IUserRespondentsBody = {
        profileId: props.profile.profileId,
        searchText,
      };
      getUserForRespondents(body, dispatch).then((response) => {
        if (response) {
          setOriginalRespondentsList(response);

          // if some respondents were magically removed from the system let's
          // remove them from the selection too.
          setListRespondents(
            listRespondents.filter((r) =>
              response.some((other) => r.id === other.id),
            ),
          );
        }
      });
    }
  }, [searchText, refetchList, props.profile, props.status]);

  // Respondents status useEffect
  useEffect(() => {
    if (props.status !== "current") {
      return;
    }

    if (props.profile.profileId && props.profile.noOfRespondents) {
      getParticipantRespondentsByParticipantId(
        props.profile.profileId,
        dispatch,
      ).then((response) => {
        if (response) {
          setInvitedRespondentsList(() => ({
            ...response,
            respondentsInvited: response.respondentsInvited ?? 0,
            respondentsAnswered: response.respondentsAnswered ?? 0,
            participantRespondents: response.respondents ?? [],
          }));
        }
      });
    }
  }, [refetchInvitedRespondentsList, props.profile, props.status]);

  useEffect(() => {
    if (
      invitedRespondentsList.respondents.length >= props.profile.noOfRespondents
    ) {
      setSelectedTab(NavTabEnums.None);
    }
    if (
      props.profile.participantInviteColleagues ||
      props.profile.participantInviteOtherParticipant
    ) {
      if (userLanguage.userLanguageCode) {
        setInstructionLanguageForAllRespondents(userLanguage.userLanguageCode);
      }
      if (
        invitedRespondentsList.respondents.length <
        props.profile.noOfRespondents
      ) {
        setSelectedTab(NavTabEnums.Select_From_List);
      }
    } else if (
      invitedRespondentsList.respondents.length < props.profile.noOfRespondents
    ) {
      setSelectedTab(NavTabEnums.Add_Manually);
    }
  }, [invitedRespondentsList, props.profile, userLanguage.userLanguageCode]);

  const handleTabSelection = (tabStatus: number): void => {
    setSelectedTab(tabStatus);
  };

  // Select from list fn's
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value);
  };

  const handleSelectRespondent = (respondent: IListRespondent): void => {
    const exists = listRespondents.some((r) => r.id === respondent.id);

    if (exists) {
      return;
    }

    const nextList = listRespondents.concat(respondent);
    const nextManual = manualRespondents.slice();

    maybeBalanceRespondents(props.profile, nextList, nextManual);

    setListRespondents(nextList);
    setManualRespondents(nextManual);
  };

  const handleRemoveRespondent = (respondent: IListRespondent): void => {
    const nextList = listRespondents.filter((r) => r.id !== respondent.id);
    const nextManual = manualRespondents.slice();

    maybeBalanceRespondents(props.profile, nextList, nextManual);

    setListRespondents(nextList);
    setManualRespondents(nextManual);
  };

  const handlePreviewModal = (): void => {
    const body: IRespondentEmailPreviewBody = {
      profileId: props.profile.profileId,
      languageCode: userLanguage.userLanguageCode,
      ownMsg: instructionMessage,
    };
    getRespondentEmailPreview(body, dispatch).then((response) => {
      if (response) {
        setPreviewHTML(response);
      }
    });
  };

  const isRespondentsLimitReached = (selectedLength: number): boolean => {
    if (
      props.profile.status === ProfileStatus.New &&
      props.profile.roleCanBeChangedByParticipant
    ) {
      // if the profile owner is allowed to change the 'role'
      // they're also allowed to invite however many respondents
      // they want.
      return false;
    }

    // If selected more than limit, show limit message
    if (
      selectedLength + invitedRespondentsList.respondents.length >
      props.profile.noOfRespondents
    ) {
      setRespondentsLimitReachedModal(true);
      return true;
    } else return false;
  };

  // Add manually fn's
  const handleFormErrors = (
    id: number | string,
    name: string,
    value: string,
    onBlur = false,
  ): void => {
    let errorMessage: string = "";
    let errorInputName: "nameError" | "emailError" | undefined;

    switch (name) {
      case ManualRespondentInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Name is required");
        }
        errorInputName = ManualRespondentInputs.nameError;
        break;
      case ManualRespondentInputs.emailAddress:
        if (value) {
          if (!ValidationHelper.isEmailValid(value)) {
            errorMessage = getLanguageValue(languageText, "Invalid email");
          } else {
            // check if email already exists in invited respondents
            const isEmailExistsInAddManually = manualRespondents
              .filter((respondent) => `${name}${respondent.id}` !== id)
              .some((respondent) => respondent.emailAddress === value);

            // check if email already added manually
            const isEmailExistsInInvitedRespondents =
              invitedRespondentsList.respondents.some(
                (respondent) => respondent.email === value,
              );
            if (
              isEmailExistsInAddManually ||
              isEmailExistsInInvitedRespondents
            ) {
              errorMessage = getLanguageValue(
                languageText,
                "Email already exists",
              );
            }
            if (value === props.profile.emailAddress) {
              errorMessage = getLanguageValue(
                languageText,
                "You cannot invite yourself as respondent",
              );
            }
          }
        }
        errorInputName = ManualRespondentInputs.emailError;
        break;
      default:
        break;
    }

    setManualRespondents((prevInfo) =>
      prevInfo.map((item) => {
        // what the fuck are we doing here? can anyone read this?
        if (
          id === `${name}${item.id}` &&
          errorInputName &&
          ((!onBlur && item[errorInputName].touched) || onBlur)
        ) {
          return {
            ...item,
            [errorInputName]: { errorMessage: errorMessage, touched: true },
          };
        }
        return item;
      }),
    );
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const id = e.target.id;
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(id, name, value, true);
  };

  const onDeleteRowClick = (index: number): void => {
    const next = manualRespondents.slice();
    next.splice(index, 1);
    setManualRespondents(next);
  };

  const onAddRowClick = (): void => {
    setManualRespondents((prevInfo) => [
      ...prevInfo,
      { ...EMPTY_MANUAL_RESPONDENT, id: manualRespondents.length + 1 },
    ]);
  };

  const closePreviewModal = (): void => {
    setPreviewHTML("");
  };

  function buildManualRespondentsRequestBody(): ICreateManualRespondentsBody[] {
    return manualRespondents
      .filter((r) => {
        const hasErrors =
          r.nameError.errorMessage ||
          r.emailError.errorMessage ||
          r.phoneNumberError.errorMessage;

        return r.name && (r.emailAddress || r.phoneNumber) && !hasErrors;
      })
      .map((info) => {
        return {
          profileId: props.profile.profileId,
          name: info.name,
          email: info.emailAddress,
          telephone: info.phoneNumber,
          instructionLanguageId: info.instructionLanguageId,
        };
      });
  }

  const closeRespondentsLimitReachedModal = (): void => {
    setRespondentsLimitReachedModal(false);
  };

  const showAddManualOption = props.profile.participantInviteExternal;
  const showSelectFormListOption =
    props.profile.participantInviteColleagues ||
    props.profile.participantInviteOtherParticipant;
  const respondentsLimitReached =
    invitedRespondentsList.respondents.length >= props.profile.noOfRespondents;

  const rolePage = useRolePage(props);

  const __t = props.__t;
  const title = __t("Invite respondents");

  const validManualRespondents = manualRespondents.filter(
    (r) => r.name && (r.emailAddress || r.phoneNumber),
  );

  const canMoveToNextStep =
    validManualRespondents.length + listRespondents.length >=
    props.profile.noOfRespondents;

  const roleNameOrText = props.profile.roleId
    ? getLanguageValue(languageText, GetTypeOfRoleName[props.profile.roleId])
    : props.profile.roleText
      ? props.profile.roleText
      : "";

  const theActualFormAndStuff = (
    <React.Fragment>
      <div className="p-4">
        <RoleSettingsWithEditOption
          languageText={languageText}
          profileId={props.profile.profileId}
          status={props.profile.status}
          roleId={props.profile.roleId}
          roleText={props.profile.roleText}
          noOfRespondents={props.profile.noOfRespondents}
          isEditable={rolePage.roleCanBeChangedByParticipant}
          isParticipant={true}
          errorMessage={rolePage.errorMessage}
          handleTypeOfRoleSave={rolePage.handleTypeOfRoleSave}
          handleNoOfRespondentsSave={rolePage.handleNoOfRespondentsSave}
          canEditNoOfRespondents={false}
        />

        <p className="mt-4">
          {getLanguageValue(
            languageText,
            "Invite the respondents you want to engage to create your IDI profile",
          )}
          .
        </p>
        {!props.profile.roleCanBeChangedByParticipant && (
          <p>
            {__t("Number of respondents required")}:{" "}
            {props.profile.noOfRespondents}
          </p>
        )}
        {!respondentsLimitReached ? (
          <>
            <div className="invite-respondents-tab fs-5 fw-bold mb-3">
              {showSelectFormListOption && showAddManualOption && (
                <div
                  id="inviteRespondentInstruction2"
                  className={clsx(
                    `me-2 btn`,
                    selectedTab === NavTabEnums.Select_From_List
                      ? "btn-dark"
                      : "btn-light",
                  )}
                  onClick={() =>
                    handleTabSelection(NavTabEnums.Select_From_List)
                  }
                >
                  <i className="bi bi-list-task me-2"></i>
                  {getLanguageValue(languageText, "Select from list")}
                </div>
              )}
              {showAddManualOption && showSelectFormListOption && (
                <div
                  id="inviteRespondentInstruction3"
                  className={clsx(
                    `btn`,
                    selectedTab === NavTabEnums.Add_Manually
                      ? "btn-dark"
                      : "btn-light",
                  )}
                  onClick={() => handleTabSelection(NavTabEnums.Add_Manually)}
                >
                  <i className="bi bi-pencil-fill me-2"></i>
                  {getLanguageValue(languageText, "Add manually")}
                </div>
              )}
            </div>

            <div className="mt-3 p-2 p-md-4 border rounded mb-4">
              {/* Select from list */}
              {showSelectFormListOption &&
                selectedTab === NavTabEnums.Select_From_List && (
                  <SelectFromList
                    languageText={languageText}
                    languages={currentUserLanguages}
                    respondentsList={originalRespondentsList}
                    searchText={searchText}
                    selectedRespondents={listRespondents}
                    handleSearch={handleSearch}
                    handleSelectRespondent={handleSelectRespondent}
                    handleRemoveRespondent={handleRemoveRespondent}
                  />
                )}

              {/* Add Manually */}
              {showAddManualOption &&
                selectedTab === NavTabEnums.Add_Manually && (
                  <>
                    <AddManually
                      languageText={languageText}
                      languages={currentUserLanguages}
                      respondentInfo={manualRespondents}
                      handleChange={(respondent, index) => {
                        const next = manualRespondents.slice();
                        next[index] = respondent;
                        setManualRespondents(next);
                      }}
                      handleBlurEvent={handleBlurEvent}
                      onDeleteRowClick={onDeleteRowClick}
                      onAddRowClick={onAddRowClick}
                    />
                    {props.profile.roleCanBeChangedByParticipant && (
                      // there are a shitload of conditions for when this button should _akshually_
                      // show up, but this seems at least partially correct. if the profile owner
                      // is allowed to edit the "role" (or whatever) is also allowed to add more respondents.
                      <div className="text-center">
                        <button
                          className="btn btn-light"
                          onClick={(event) => {
                            event.preventDefault();

                            const next = manualRespondents.concat({
                              ...EMPTY_MANUAL_RESPONDENT,

                              // the delete method needs an ID for some reason.
                              id: manualRespondents.length + 1,
                            });
                            setManualRespondents(next);
                          }}
                        >
                          <i className="bi bi-plus-lg" />{" "}
                          {__t("Add respondent")}
                        </button>
                      </div>
                    )}
                  </>
                )}
            </div>
          </>
        ) : (
          <div className="text-danger d-none">
            {
              getLanguageValue(
                languageText,
                "Respondents limit reached. Cannot add further respondents",
              )
              //AM: not sure if we will keep this
            }
            .
          </div>
        )}

        <SendRespondentsInvitation
          languageText={languageText}
          messageValue={instructionMessage}
          handleSaveMessage={(message) => {
            setInstructionMessage(message);
            api.saveParticipantFieldValue({
              id: props.profile.profileId,
              fieldName: "InstructionMessage",
              fieldValue: message,
            });
          }}
          onPreviewClick={handlePreviewModal}
        />
      </div>
      {previewHTML && (
        <PreviewPopup
          languageText={languageText}
          previewHTML={previewHTML}
          onCloseClick={closePreviewModal}
        />
      )}

      {respondentsLimitReachedModal && (
        <RespondentsLimitReachedModal
          headerText={getLanguageValue(languageText, "Limit Reached")}
          bodyText={`${getLanguageValue(languageText, "Maximum")} ${
            props.profile.noOfRespondents
          } ${getLanguageValue(languageText, "respondents can be invited")}.`}
          cancelButtonText={getLanguageValue(languageText, "Ok")}
          handleCancelClick={closeRespondentsLimitReachedModal}
        />
      )}
    </React.Fragment>
  );

  /**
   * This first checks if the total sum of invitees (list and manual) is higher
   * than the allowed and then sends the request to add them.
   * @returns
   */
  function saveRespondentsAndMaybeSendInvites(): Promise<void> {
    const manualRespondents = buildManualRespondentsRequestBody();
    if (listRespondents.length > 0 || manualRespondents.length > 0) {
      if (
        isRespondentsLimitReached(
          manualRespondents.length + listRespondents.length,
        )
      ) {
        return Promise.reject();
      }

      dispatch(setSpinner(true));
      const params: ICreateRespondentsParams = {
        profileId: props.profile.profileId,
      };

      // Create body from the selected respondents
      const respondentsFromList: Array<ICreateManualRespondentsBody> =
        listRespondents.map((r) => {
          return {
            name: r.name,
            email: r.emailAddress,
            telephone: r.phoneNumber,
            instructionLanguageId: r.instructionLanguageId,
          };
        });

      const respondentsFromManual = buildManualRespondentsRequestBody();
      const allRespondents = [...respondentsFromList, ...respondentsFromManual];

      return createManualProfileParticipantRespondents(
        allRespondents,
        params,
        dispatch,
      )
        .then((response) => {
          if (response?.success) {
            setRefetchList(!refetchList);
            setManualRespondents([EMPTY_MANUAL_RESPONDENT]);
            setRefetchInvitedRespondentsList(!refetchInvitedRespondentsList);
            onInvited();
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
    return Promise.resolve();
  }

  if (props.variant.kind === "modal") {
    // for an explanation for why this garbage is here, see the rant above in the props declaration.
    // this component should obviously not "be" two things at once, but the form is inherently
    // tighly coupled to the hook 'useInviteRespondent'.

    const variant = props.variant;
    return (
      <ModalComponent
        headerText={__t("Add respondent")}
        submitButtonText={__t("Submit")}
        handleSubmitClick={(event) => {
          event.preventDefault();
          saveRespondentsAndMaybeSendInvites().then(() => {
            variant.onSubmit();
          });
        }}
        cancelButtonText={__t("Cancel")}
        handleCancelClick={(event) => {
          event.preventDefault();
          variant.onClose();
        }}
        width="xl"
      >
        {theActualFormAndStuff}
      </ModalComponent>
    );
  }

  switch (props.status) {
    case "upcoming":
      return <UpcomingStepPanel title={title} />;
    case "current":
      return (
        <CurrentStepPanel
          __t={__t}
          title={
            <div className="d-flex">
              <div className="flex-grow-1">{title}</div>
              <div className="flex-grow-0">
                <Instruction
                  targetElement="instructionBtn"
                  //Shouldn't this be GuidePages.Participant_Invite_Respondents? - Joakim, 241112
                  guidePage={GuidePages.Participant_Role}
                  instructionSteps={instructionSteps}
                  /* is the '4' here accurate? is it an index? is it a step 'number'? */
                  stepsNotToSkip={
                    !rolePage.roleCanBeChangedByParticipant ? [4] : []
                  }
                  manualTrigger={true}
                />
              </div>
            </div>
          }
          onPreviousClick={(event) => {
            event.preventDefault();
            props.setStep(Step.DemographicSurvey);
          }}
          onNextClick={(event) => {
            event.preventDefault();

            saveRespondentsAndMaybeSendInvites();
          }}
          nextDisabled={!canMoveToNextStep}
        >
          {theActualFormAndStuff}
        </CurrentStepPanel>
      );
    case "completed": {
      const title = (
        <span>
          {__t("My respondents are")}{" "}
          <span className="fw-bold">
            {props.profile.respondents.length} {roleNameOrText}
          </span>
          {props.profile.respondents.map((r, index) => {
            return (
              <span
                key={index}
                className="ms-2 py-1 px-2 small bg-dark bg-opacity-10 rounded"
              >
                {getShortName(r.name)}
              </span>
            );
          })}
        </span>
      );

      return <CompletedStepPanel title={title} />;
    }
  }
};
